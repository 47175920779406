var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('banner',{attrs:{"title":"Reservas","type":"banner--admin","options":{padding: '30px'}}}),_c('div',{staticClass:"pt-3 px-7"},[_c('booking-filters',{attrs:{"params":_vm.params},on:{"search":_vm.search,"clean":_vm.clean}}),(_vm.fairName)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center red--text"},[_c('h2',[_vm._v(_vm._s(_vm.fairName))])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{ref:"bookings",attrs:{"url":_vm.url,"params":_vm.params,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.identification",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.identification)+" ")])]}},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.companyName)+" ")])]}},{key:"item.totalValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prefix(item))+" "+_vm._s(_vm._f("currency")(_vm.calculateWithTax(item)))+" "+_vm._s(_vm.suffix(item))+" ")]}},{key:"item.firstContactName",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"ma-2 elevation-0",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.showContactInfo(item)}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"center":"","x-small":"","color":"white"}},[_vm._v(" fa fa-eye ")])],1)]}},{key:"item.standsAmount",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"ma-2 elevation-0",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.showStandsInfo(item)}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"center":"","x-small":"","color":"white"}},[_vm._v(" fa fa-eye ")])],1),_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.standsAmount)+" ")])]}},{key:"item.currentStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.bookingStatus[item.currentStatus].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.bookingStatus[item.currentStatus].description)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.currentStatus === _vm.bookingStatus.new.code)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"small":"","color":"#13E23E"},on:{"click":function($event){return _vm.approve(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":""}},[_vm._v(" fa fa-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aprobar reserva")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.reject(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","color":"white"}},[_vm._v(" fa fa-times ")])],1)]}}],null,true)},[_c('span',[_vm._v("Rechazar reserva")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.currentStatus === _vm.bookingStatus.successful.code)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.generateAgreement(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","center":"","x-small":""}},[_vm._v(" fa fa-file-pdf ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Contrato")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showAudit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","small":"","dark":""}},[_vm._v(" fa fa-info-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Auditoria")])]),(item.currentStatus !== 'new' && item.currentStatus !== 'rejected')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.sendCredentials(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","small":"","dark":""}},[_vm._v(" fa fa-share ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reenviar credenciales")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.fairIsActive)?_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showBookingInfo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","small":"","dark":""}},[_vm._v(" fas fa-search ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Ver reserva")])])]}}])})],1)]),_c('contact-info-modal',{ref:"contactInfoModal"}),_c('stands-info-modal',{ref:"standsInfoModal"}),_c('audit-info-modal',{ref:"auditInfoModal"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }